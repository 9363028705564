/* eslint-disable react/prop-types */
import { CSSProperties } from "react";
import style from "./bodyRowPage.module.scss";

type BodyRowPageProps = {
  children: React.ReactNode;
  paddingTop?: number;
  paddingBottom?: number;
  customStyle?: CSSProperties;
};

const BodyRowPage: React.FC<BodyRowPageProps> = ({ children, paddingTop, paddingBottom, customStyle }) => {
  return (
    <div
      className={style.bodyRowPage}
      style={{ paddingTop: `${paddingTop}px`, paddingBottom: `${paddingBottom}px`, ...customStyle }}
    >
      {children}
    </div>
  );
};

export default BodyRowPage;
