import { fullBasePath } from "@constants/index";
import { generateBreadCrumbs, generateBreadCrumbUrl } from "@utils/strings/generateBreadCrumbs.util";
import { useRouter } from "next/router";
import { useMemo } from "react";

const useBreadcrumb = () => {
  const router = useRouter();

  const { urlBreadcrumText } = useMemo(() => {
    const [path] = router.asPath.split("?");
    const pathname = decodeURIComponent(path);
    return { urlParam: pathname, urlBreadcrumText: pathname.replace(/-/gi, " ") };
  }, [router.asPath]);

  const homeUrl = fullBasePath;

  const breadCrumbsList = generateBreadCrumbs(urlBreadcrumText);

  const breadcrumbProps = {
    breadcrumbText: urlBreadcrumText,
    baseUrl: `${fullBasePath}/`,
    haveRedirect: true,
    detailTitle: "",
    breadCrumbFromServer: breadCrumbsList,
    urlFromServer: generateBreadCrumbUrl(breadCrumbsList, `${fullBasePath}/`)
  };
  return { homeUrl, breadcrumbProps };
};

export default useBreadcrumb;
